<template>
  <b-container>
    <b-row class="pt-4 pb-3">
      <b-col
        cols="10"
        class="d-flex align-items-center"
        style="height: 35px"
      >
        <h4 class="text-primary d-inline-block mb-0">Abstract</h4>
      </b-col>
      <b-col
        cols="2"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          class="float-right"
          variant="info"
          target="_blank"
          href="https://assets.premialab.com/newsletter/White%20Paper%20%7C%20Advanced%20Factor%20Analytics%202022.pdf"
          @click="trackButton"
        >
          Download
        </b-button>
      </b-col>
      <b-col />
    </b-row>
    <b-row class="pt-2">
      <b-col>
        <p class="text-justify">
          This paper outlines an advanced statistical and analytical methodology to benchmark, classify, and assess the
          universe of quantitative investment strategies deployed in the market.
        </p>
        <p class="text-justify">
          Using a rigorous, top-down analysis of our extensive strategy database of leading providers, we will introduce
          the Pure Factor framework to model seven universally accepted style premia - Value, Size, Quality, Carry,
          Momentum, Volatility and Low Volatility - within five different asset classes.
        </p>
        <p class="text-justify">
          We shall then analyse how Premialab Pure Factors provide a unique referential to benchmark the universe and
          measure factor exposures, as well as their performance attribution across asset classes and investment styles.
        </p>
        <p class="text-justify">
          A comprehensive correlation study demonstrates the lack of intra correlation of Premialab Pure Factors and
          showcases the dynamics of each factor versus its relevant market beta.
        </p>
        <p class="text-justify mb-0">
          Furthermore, the factor model is tested on a wide range of investment products - Equities, Mutual Funds, Hedge
          Funds (CTAs, Global Macro, L/S Equity, Risk Premia Funds), and Fund of Hedges Funds, demonstrating a unique
          explanatory power whilst quantitatively decomposing their risk and performance drivers.
        </p>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-row>
          <b-col>
            <b-card-group deck>
              <b-card body-bg-variant="light">
                <h5 class="text-primary font-weight-400 mb-3">Get in touch</h5>
                <p>
                  <span class="pb-1"> Contact our teams in: </span>
                </p>
                <ul class="pl-4">
                  <li class="py-1">
                    <a
                      :href="`mailto:${asiaSalesEmail}`"
                      @click="trackContact('Asia')"
                      >Asia</a
                    >
                  </li>
                  <li class="py-1">
                    <a
                      :href="`mailto:${euroSalesEmail}`"
                      @click="trackContact('Europe')"
                      >Europe</a
                    >
                  </li>
                  <li class="py-1">
                    <a
                      :href="`mailto:${usSalesEmail}`"
                      @click="trackContact('North America')"
                      >North America</a
                    >
                  </li>
                  <li class="py-1">
                    <a
                      :href="`mailto:${clientServicesEmail}`"
                      @click="trackContact('All other areas')"
                      >All other areas</a
                    >
                  </li>
                </ul>
              </b-card>
              <b-card body-bg-variant="light">
                <h5 class="text-primary font-weight-400 mb-3">See it in action</h5>
                <p>Read the Monthly Pure Factor Report and monitor Factor risk performance.</p>
                <p>Gain insights into Factor behavior and update your Factor understanding.</p>
                <div class="d-flex justify-content-center">
                  <b-button
                    variant="info"
                    :to="{
                      name: pureFactorRoute,
                    }"
                  >
                    Monthly Factor Report
                  </b-button>
                </div>
              </b-card>
              <b-card body-bg-variant="light">
                <h5 class="text-primary font-weight-400 mb-3">Applications</h5>
                <p>Our Pure Factors can support various workflows:</p>
                <ul class="pl-4">
                  <li>Portfolio Analysis</li>
                  <li>Risk & Return Contribution Analysis</li>
                  <li>Research</li>
                </ul>
                <p>
                  <a
                    :href="`mailto:${clientServicesEmail}`"
                    @click="trackContact('Client Services')"
                    >Contact our team</a
                  >
                  to schedule a live demo.
                </p>
              </b-card>
            </b-card-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script lang="ts">
import useAppMetrics, { useTrackPageView, identify } from '@/composables/useAppMetrics';
import { RouteName } from '@/constants/RouteName';
import { ACCEPTED_TRACKED_TITLES } from '@/types/analytics';
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'WhitepaperPage',
  setup() {
    const { track } = useAppMetrics();

    const clientServicesEmail = import.meta.env.VITE_CLIENT_SERVICES_EMAIL;

    const euroSalesEmail = 'sales-emea@premialab.com';
    const usSalesEmail = 'sales-us@premialab.com';
    const asiaSalesEmail = 'sales-asia@premialab.com';

    const pageName = RouteName.INSIGHTS;
    const subpageName = 'Whitepaper';

    useTrackPageView({ pageName, subpageName });

    const trackContact = (contact: string) => {
      track(ACCEPTED_TRACKED_TITLES.BUTTON_CLICKED, {
        pageName,
        subpageName,
        buttonName: 'Contact Team',
        team: contact,
      });
    };
    const trackButton = () => {
      track(ACCEPTED_TRACKED_TITLES.BUTTON_CLICKED, {
        pageName,
        subpageName,
        buttonName: 'Download Whitepaper',
      });
    };

    onMounted(() => {
      identify();
      track(ACCEPTED_TRACKED_TITLES.PAGE_VIEWED, {
        pageName,
        subpageName,
      });
    });

    return {
      trackContact,
      trackButton,
      clientServicesEmail,
      euroSalesEmail,
      usSalesEmail,
      asiaSalesEmail,
      pureFactorRoute: RouteName.PURE_FACTORS,
    };
  },
});
</script>
